




import { Component, Vue } from 'vue-property-decorator';
import Form from '@/components/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class TrueFalseForm extends Vue {}
